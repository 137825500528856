import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  Modal,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import Iconify from '../../../../components/Iconify';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import CustomModal from '../../../../components/CustomModal';
import { ThemeButton } from '../../../GlobalStyles';

const TitleBar = styled(Box)(({ theme }) => ({
  width: '30%',
  minWidth: '150px',
  height: '35px',
  background: 'linear-gradient(to bottom, #4d4d4d, #262626)',
  color: 'white',
  fontSize: '24px',
  textAlign: 'center',
  borderBottomLeftRadius: '30px',
  borderBottomRightRadius: '30px',
  border: '3px solid #262626',
  //   boxShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
  padding: '10px',
  borderTop: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const snackbarOptionsSuccess = { variant: 'success', autoHideDuration: 5000 };
const snackbarOptionsError = { variant: 'error', autoHideDuration: 5000 };

const ResetPasswordDialog = ({ openResetPasswordDialog, setOpenResetPasswordDialog, setOpenLoginSignup }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const [newPassword, setNewPassword] = useState('');

  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState({
    newPassword: '',
    confirmNewPassword: '',
  });

  const onSubmit = () => {
    if (newPassword.trim().length < 6) {
      setErrors((prev) => ({ ...prev, newPassword: translate('password_atleast_6_characters') }));
      return;
    }

    if (confirmNewPassword.trim().length < 6) {
      setErrors((prev) => ({ ...prev, confirmNewPassword: translate('password_atleast_6_characters') }));
      return;
    }

    if (confirmNewPassword.trim() !== newPassword.trim()) {
      setErrors((prev) => ({ ...prev, confirmNewPassword: translate('password_not_match') }));
      return;
    }

    setIsSubmitting(true);
    conObj
      .post(`password_reset/${sessionStorage.getItem('resetToken')}.json`, { 'user-new_password': newPassword.trim() })
      .then((res) => {
        if (res.status === 200) {
          const { msg, status } = res.data?.data;
          if (status === 1) {
            sessionStorage.removeItem('resetToken');
            enqueueSnackbar(translate('password_reset_success'), snackbarOptionsSuccess);
            setOpenLoginSignup({ open: true, isLogin: true });
          }
        }
      })
      .catch((err) => {
        sessionStorage.removeItem('resetToken');
        const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
        enqueueSnackbar(err.message, snackbarOptions);
        console.log(`Server Error: ${err.message}`);
      })
      .then(() => {
        setOpenResetPasswordDialog(false);
        setIsSubmitting(false);
      });
  };

  return (
    <CustomModal
      open={openResetPasswordDialog}
      onClose={() => setOpenResetPasswordDialog(false)}
      sx={{ overflow: 'hidden' }}
      hasClose={false}
    >
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <TitleBar>
            <Typography fontWeight={700}>{translate('reset_password')}</Typography>
          </TitleBar>
        </Box>
        <Box
          sx={{
            p: 1,
          }}
        >
          <Typography fontWeight={700}>{translate('please_enter_new_password')}</Typography>
          <Box sx={{ mb: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                borderRadius: '10px',
                border: '1px solid #D9D0F1',
                height: '44px',
                p: '8.5px 13px',
                backgroundColor: '#120033',
              }}
            >
              <Iconify icon={'ph:lock-light'} width={24} height={24} sx={{ color: '#D9D0F1' }} />

              <InputBase
                type={showPassword ? 'text' : 'password'}
                name="newPassword"
                value={newPassword}
                placeholder={translate('new_password')}
                //   InputLabelProps={{
                //     style: { color: '#999999' },
                //  }}
                sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: 'white' }}
                onChange={(e) => setNewPassword(e.target.value)}
                error={!!errors?.newPassword}
              />
              <IconButton onClick={() => setShowPassword((prev) => !prev)} size={'small'}>
                <Iconify
                  icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off'}
                  width={24}
                  height={24}
                  sx={{ color: '#D9D0F1' }}
                />
              </IconButton>
            </Stack>
            {errors?.newPassword ? (
              <FormHelperText variant="outlined" sx={{ color: 'error.main' }}>
                {errors?.newPassword}
              </FormHelperText>
            ) : (
              ''
            )}
          </Box>

          <Box sx={{ mb: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                borderRadius: '10px',
                border: '1px solid #D9D0F1',
                height: '44px',
                p: '8.5px 13px',
                backgroundColor: '#120033',
              }}
            >
              <Iconify icon={'ph:lock-light'} width={24} height={24} sx={{ color: '#D9D0F1' }} />

              <InputBase
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirmNewPassword"
                value={confirmNewPassword}
                placeholder={translate('confirm_new_password')}
                //   InputLabelProps={{
                //     style: { color: '#999999' },
                //  }}
                sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: 'white' }}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                error={!!errors?.confirmNewPassword}
              />
              <IconButton onClick={() => setShowConfirmPassword((prev) => !prev)} size={'small'}>
                <Iconify
                  icon={showConfirmPassword ? 'mdi:eye-outline' : 'mdi:eye-off'}
                  width={24}
                  height={24}
                  sx={{ color: '#D9D0F1' }}
                />
              </IconButton>
            </Stack>
            {errors?.confirmNewPassword ? (
              <FormHelperText variant="outlined" sx={{ color: 'error.main' }}>
                {errors?.confirmNewPassword}
              </FormHelperText>
            ) : (
              ''
            )}
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ borderColor: '#D9D0F1', color: '#D9D0F1' }}
                onClick={() => setOpenResetPasswordDialog(false)}
              >
                {translate('cancel')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <ThemeButton sx={{ width: '100%' }} loading={isSubmitting} onClick={() => onSubmit()}>
                {translate('confirm')}
              </ThemeButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </CustomModal>
  );
};

ResetPasswordDialog.propTypes = {
  openResetPasswordDialog: PropTypes.bool,
  setOpenResetPasswordDialog: PropTypes.func,
  setOpenLoginSignup: PropTypes.func,
};

export default ResetPasswordDialog;
