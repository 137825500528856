// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  game: `গেম`,
  games: `গেমস`,
  sport: `ক্রীড়া`,
  center: `কেন্দ্র`,
  mines: `মাইনস`,
  crash: `ক্র্যাশ`,
  lucky_colour: `লাকি কালার`,
  dice: `ডাইস`,
  limbo: `লিম্বো`,
  lucky_gift: `লাকি গিফট`,
  all_games: `সকল গেম`,
  game_history: `গেম ইতিহাস`,
  coupon_code: `কুপন কোড`,
  telegram: `টেলিগ্রাম`,
  customer_service: `গ্রাহক সেবা`,
  player: `প্লেয়ার`,
  winner: `জয়ী`,
  all_bets: `সকল বাজি`,
  my_bets: `আমার বাজি`,
  name: `নাম`,
  time: `সময়`,
  bet_amount: `বাজির পরিমাণ`,
  prediction: `ভবিষ্যদ্বাণী`,
  payout: `পে-আউট`,
  login: `লগইন`,
  register: `নিবন্ধন`,
  username_placeholder: `ব্যবহারকারীর নাম`,
  please_input_your_x: `অনুগ্রহ করে আপনার {{x}} ইনপুট করুন`,
  verification_code: `যাচাইকরণ কোড`,
  press_get_code: `যাচাইকরণ কোড পেতে GET CODE বোতামে ক্লিক করুন`,
  otp_sent: `যাচাইকরণ কোড পাঠানো হয়েছে! চালিয়ে যাওয়ার জন্য নিচে যাচাইকরণ কোড প্রবেশ করুন`,
  your_otp_expire: `আপনার OTP শেষ হবে`,
  get_code: `GET CODE`,
  didnt_receive: `বার্তা পাননি?`,
  send_again: `পুনরায় পাঠান`,
  captcha: `ক্যাপচা`,
  forgot_password: `পাসওয়ার্ড ভুলে গেছেন`,
  minor_note: `এই সাইটে প্রবেশ করতে নিশ্চিত করুন যে আপনি ১৮ বছরের বেশি এবং সম্মতি দেন`,
  terms_of_service: `সেবার শর্তাবলী`,
  by_signing_in: 'লগইন বা নিবন্ধন করে, আপনি আমাদের সাথে একমত হন',
  dont_have_account: `কোনও অ্যাকাউন্ট নেই?`,
  sign_up: `সাইন আপ`,
  username: `ব্যবহারকারীর নাম`,
  email: `ইমেইল`,
  full_name: `পূর্ণ নাম`,
  confirm_18: `আমি নিশ্চিত যে আমি ১৮ বছর বয়সী এবং আমি পড়েছি`,
  already_have_account: `আগেই একটি অ্যাকাউন্ট আছে?`,
  share: `শেয়ার করুন`,
  deposit: `জমা`,
  bonus: `বোনাস`,
  app: `অ্যাপ`,
  load_more: `আরও লোড করুন`,
  details: `বিস্তারিত`,
  helps: `সাহায্য`,
  kyc_policy: `কেওয়াইসি নীতি`,
  privacy_policy: `গোপনীয়তা নীতি`,
  responsible_gaming: `দায়িত্বশীল গেমিং`,
  terms_and_condition: `শর্তাবলী`,
  faq: `প্রশ্নোত্তর`,
  about_us: `আমাদের সম্পর্কে`,
  amount: `পরিমাণ`,
  extra: `অতিরিক্ত`,
  deposit_bonus: `জমা বোনাস`,
  do_not_participate: `প্রচারাভিযানে অংশগ্রহণ করবেন না`,
  payment_problem: `পেমেন্ট সমস্যা`,
  click_for_help: `সাহায্যের জন্য ক্লিক করুন`,
  deposit_notes: `জমার নোট`,
  available_balance: `উপলব্ধ ব্যালেন্স`,
  withdraw: `উত্তোলন`,
  withdrawal_notes: `উত্তোলন নোট`,
  withdrawal_note1: `বর্তমান উত্তোলনের সংখ্যা`,
  withdrawal_note1_1: `ভিআইপি`,
  withdrawal_note2: `উত্তোলন ফি:`,
  min: `নূন্যতম:`,
  max: `সর্বাধিক:`,
  opening_times: `খোলার সময়:`,
  withdrawal_note3: `১. ১০ মিনিটের মধ্যে দ্রুততম উত্তোলন সময়`,
  withdrawal_note4: `২. নিশ্চিত করুন যে আপনার উত্তোলন তথ্য সঠিক, যদি উত্তোলন তথ্য ভুল হয় তবে এটি আপনার তহবিল হারাতে পারে।`,
  withdrawal_note5: `৩. যদি আপনার কোন প্রশ্ন থাকে তবে আমাদের সাথে যোগাযোগ করতে পারেন।`,
  notifications: `বিজ্ঞপ্তি`,
  invalid_x: `অবৈধ {{x}}`,
  captcha_not_match: `ক্যাপচা মেলে না`,
  invalid_x_must_be_atleast_y_characters: `অবৈধ {{x}}, কমপক্ষে {{y}} অক্ষর হতে হবে`,
  x_is_required: `{{x}} আবশ্যক`,
  phone_number: 'ফোন নম্বর',
  phone_num_x_max: `ফোন নম্বর সর্বাধিক {{x}} অক্ষর`,
  email_sent: `ইমেইল পাঠানো হয়েছে, দয়া করে আপনার ইমেইল চেক করুন।`,
  enter_valid_email: `দয়া করে একটি বৈধ ইমেইল প্রবেশ করুন।`,
  confirm: `নিশ্চিত করুন`,
  password_atleast_6_characters: `পাসওয়ার্ড কমপক্ষে ৬ অক্ষরের হতে হবে।`,
  password_reset_success: `পাসওয়ার্ড পুনরায় সেট সফল!`,
  new_password: `নতুন পাসওয়ার্ড`,
  bank: 'ব্যাংক',
  bank_transfer: `ব্যাংক ট্রান্সফার`,
  bank_name: `ব্যাংকের নাম`,
  bank_branch: `ব্যাংক শাখা`,
  pay_id: `পে আইডি`,
  account_name: `অ্যাকাউন্টের নাম`,
  account_number: `অ্যাকাউন্ট নম্বর`,
  drop_or_select: `ফাইল ড্রপ বা নির্বাচন করুন`,
  drop_files_here: `ফাইলগুলি এখানে ড্রপ করুন`,
  transfer_to: `ট্রান্সফার করুন`,
  upload_receipt: `রসিদ আপলোড করুন`,
  select_bonus: `বোনাস নির্বাচন করুন`,
  no_promotion: `কোন প্রচার নেই`,
  please_upload_receipt: `দয়া করে রসিদ আপলোড করুন`,
  bank_account: `ব্যাংক অ্যাকাউন্ট`,
  add_account: `অ্যাকাউন্ট যোগ করুন`,
  account_holder_name: `অ্যাকাউন্ট হোল্ডারের নাম`,
  name_of_the_account_holder: `অ্যাকাউন্ট হোল্ডারের নাম`,
  fill_in_bank_account: `ব্যাংক অ্যাকাউন্ট পূরণ করুন`,
  withdraw_information: `উত্তোলন তথ্য`,
  note: `নোট`,
  amount_withdrawn: `উত্তোলিত পরিমাণ`,
  amount_no_exceed_balance: `উত্তোলনের পরিমাণ উপলব্ধ ব্যালেন্সের চেয়ে বেশি হওয়া উচিত নয়`,
  minimum_withdraw_amount_is_x: `নূন্যতম উত্তোলন পরিমাণ {{x}}`,
  transaction_history: `লেনদেনের ইতিহাস`,
  date_from: `তারিখ থেকে`,
  date_to: `তারিখ পর্যন্ত`,
  account_name_must_be_4: `অ্যাকাউন্টের নামের দৈর্ঘ্য ৪ এর বেশি হতে হবে`,
  account_number_must_be_x: `অ্যাকাউন্ট নম্বরের দৈর্ঘ্য ১০-১৪ এর মধ্যে হতে হবে`,
  minimum_length_is_x: `নূন্যতম দৈর্ঘ্য {{x}}`,
  maximum_length_is_x: `সর্বাধিক দৈর্ঘ্য {{x}}`,
  payment_method: `পেমেন্ট পদ্ধতি`,
  mode: `মোড`,
  no_bank_account: `আপনার উত্তোলনের জন্য কোন অ্যাকাউন্ট নেই, এখন যোগ করুন`,
  please_select_bank: `দয়া করে একটি ব্যাংক অ্যাকাউন্ট নির্বাচন করুন`,

  bet_history: `বাজির ইতিহাস`,
  game_provider: `গেম প্রদানকারী`,
  bet_count: `বাজির সংখ্যা`,
  win_loss: `জয়/হারে`,
  rebate: `রিবেট`,
  turnover: `টার্নওভার`,
  game_category: `গেম বিভাগ`,

  you_have_x_unread_messages: `আপনার {{x}} পড়া হয়নি এমন বার্তা রয়েছে`,
  deposit_amount_between_x_and_y: `জমার পরিমাণ {{x}} এবং {{y}} এর মধ্যে হতে হবে`,
  minimum_amount_is_x_y: `নূন্যতম পরিমাণ {{x}} {{y}}`,

  // all
  all: `সব`,
  title: `শিরোনাম`,
  date: `তারিখ`,
  type: `প্রকার`,
  status: `স্থিতি`,
  remark: `মন্তব্য`,
  category: `বিভাগ`,
  please_select_x: `অনুগ্রহ করে {{x}} নির্বাচন করুন`,
  save: `সংরক্ষণ করুন`,

  change_password: `পাসওয়ার্ড পরিবর্তন করুন`,
  current_password: `বর্তমান পাসওয়ার্ড`,
  confirm_password: `নতুন পাসওয়ার্ড নিশ্চিত করুন`,
  submit: `জমা দিন`,
  password_changed: `পাসওয়ার্ড পরিবর্তন করা হয়েছে`,
  x_must_be_y_characters: `{{x}} এর দৈর্ঘ্য কমপক্ষে {{y}} অক্ষর হতে হবে`,
  reset_password: `পাসওয়ার্ড পুনরায় সেট করুন`,
  casino: `ক্যাসিনো`,
  slots: `স্লটস`,
  live_casino: `লাইভ ক্যাসিনো`,
  fishing: `মাছ ধরা`,
  sports: `ক্রীড়া`,
  cards: `কার্ডস`,
  clear: `স্পষ্ট`,
  search: `অনুসন্ধান`,
  new: `নতুন`,
  logout: `লগআউট`,
  choose_date: `তারিখ নির্বাচন করুন`,
  select_both_date: `তারিখ থেকে এবং পর্যন্ত উভয় নির্বাচন করুন`,
  from: `থেকে`,
  to: `পর্যন্ত`,
  apply: `আবেদন করুন`,
  promotions: `প্রচার`,
  learn_more: `আরও জানুন`,
  password_doesnt_match: `পাসওয়ার্ড মেলে না`,
  cancel: `বাতিল করুন`,
  confirm_to_pay: `পেমেন্ট নিশ্চিত করুন`,
  deposit_amount: `জমার পরিমাণ`,
  loading_please_wait: `লোড হচ্ছে, দয়া করে অপেক্ষা করুন`,
  account: `অ্যাকাউন্ট`,
  profile: `প্রোফাইল`,
  bank_list: `ব্যাংক তালিকা`,
  in_maintenance: `রক্ষণাবেক্ষণে`,
  birthday: `জন্মদিন`,
  birthday_bonus: `একটি বোনাসের জন্য আপনার জন্মদিন প্রবেশ করুন! 🎉🎁`,
  referral_code: `রেফারেল কোড`,
  referral: `রেফারেল`,
  my_referral: `আমার রেফারেল`,
  my_claim: `আমার দাবি`,
  claim_profit: `লাভ দাবি করুন`,
  available_profit: `উপলব্ধ লাভ`,
  claimed_profit: `দাবি করা লাভ`,
  all_time_profit: `সমস্ত সময়ের লাভ`,
  referral_note_on_x: `আপনার বন্ধু {{x}} এ খেললে প্রতি বার লাভ করুন`,
  tier: `টিয়ার`,
  commission: `কমিশন`,
  referred_member: `রেফার করা সদস্য`,
  profit_earned: `লাভ অর্জিত`,
  account_information: `অ্যাকাউন্ট তথ্য`,
  invite_friends: `বন্ধুদের আমন্ত্রণ জানান`,
  how_it_work: `এটি কিভাবে কাজ করে?`,
  invite_note: `আপনার রেফারেল কোড ব্যবহার করে আপনার বন্ধু নিবন্ধন করলে একচেটিয়া রেফারেল বোনাস পান!`,
  share_via: `দ্বারা শেয়ার করুন`,
  referral_link: `রেফারেল লিংক`,
  total_profit: `মোট লাভ`,
  copied: `কপি করা হয়েছে`,
  nothing_to_claim: `দাবি করার কিছু নেই`,
  claim_history: `দাবি ইতিহাস`,
  downline: `ডাউনলাইন`,
  last_x_digit_bank_ref_number: `ব্যাংক রেফারেন্স নম্বরের শেষ {{x}} ডিজিট`,
  bank_ref_number_validation_x: `ব্যাংক রেফারেন্স নম্বর আবশ্যক এবং {{x}} অক্ষরের দৈর্ঘ্য হতে হবে`,
  sort: `সাজান`,
  login_directly: `সরাসরি লগইন করুন`,
  our_game_provides: `আমাদের গেম প্রদানকারীরা`,
  refer_and_reward: `রেফার এবং পুরস্কৃত করুন`,
  linked_successfully_x: `সফলভাবে {{x}} এর সাথে সংযুক্ত! দয়া করে প্রথমবারের জন্য আপনার অ্যাকাউন্ট নিবন্ধন করুন`,
  more_x_of_y: `আরও {{x}}/{{y}}`,
  default: `ডিফল্ট`,
  promotion: `প্রচার`,
  daily_mission: `দৈনিক মিশন`,
  agent: `এজেন্ট`,
  contact_us: `আমাদের সাথে যোগাযোগ করুন`,
  download: `ডাউনলোড`,
  cash_claim: `নগদ দাবি`,
  demo: `ডেমো`,
  favorites: `প্রিয়`,
  popular: `জনপ্রিয়`,
  claim_bonus: `বোনাস দাবি করুন`,
  insufficient_balance: `অপর্যাপ্ত ব্যালেন্স`,
  currently_displaying: `বর্তমানে {{z}} থেকে {{y}} {{x}} গেম দেখানো হচ্ছে`,
  all_x: `সব {{x}}`,
  no_results: `কোন ফলাফল পাওয়া যায়নি`,
  search_games: `গেম অনুসন্ধান করুন`,
  please_scan_using_your: `দয়া করে আপনার ব্যবহার করুন স্ক্যান করুন`,
  vip_level: `ভিআইপি স্তর`,
  bonus_direct: `ডাইরেক্ট বোনাস`,
  rebate_level: `রিবেট স্তর`,
  vip_header: `ড্রিমউইন75 এর স্লট মেশিন, বোর্ড এবং কার্ড গেম, মাছ ধরা, ভিডিও, ক্রীড়া এবং লটারি গেমে রাখা সমস্ত বেট স্থায়ীভাবে সংগ্রহ করা হবে। বৈধ বেট = স্থায়ী আয় এবং অর্থবান!`,
  vip_example: `
  উদাহরণ ১: যারা ১ মিলিয়ন জমা দিয়ে পৌঁছায়, তারা VIP লেভেল ২ এ পৌঁছায়, R$210 বোনাস পায় এবং ৩.৫০% রিবেট পায়। যখন তারা ৬ মিলিয়ন জমাতে পৌঁছায়, তারা VIP লেভেল ৩ এ পৌঁছায় এবং R$800 বোনাস পায়, ৪% রিবেট সহ।`,
  activity_rules: `কর্মসূচির নিয়ম:`,
  activity_rules_1: `১. সমস্ত বোনাসের জন্য তোলা অনুরোধ করার জন্য x3 উইনওভার থাকা প্রয়োজন।`,
  activity_rules_2: `২. রিবেট বোনাস নির্ধারিত সময়ের মধ্যে দাবি করুন, যদি আপনি বিলম্ব করেন, তাহলে এটি ত্যাগ হিসেবে বিবেচিত হবে;`,
  activity_rules_3: `৩. সদস্যদের কার্যক্রমে অংশগ্রহণ স্বয়ংক্রিয়ভাবে সিস্টেম দ্বারা গণনা করা হয়, যদি কোনও আপত্তি থাকে, তবে ড্রিমউইন75 এর তদন্তের ফলাফল প্রাধান্য পাবে;`,
  activity_rules_4: `৪. যদি আপনি আপনার সদস্য অ্যাকাউন্ট/পাসওয়ার্ড ভুলে যান, তাহলে দয়া করে "২৪-ঘন্টা অনলাইন গ্রাহক সেবা" এর সাথে যোগাযোগ করুন আপনার অ্যাকাউন্ট তথ্য পুনরুদ্ধার করতে সাহায্য করার জন্য;`,
  activity_rules_5: `৫. ড্রিমউইন75 যেকোন সময় এই প্রচারটি পরিবর্তন, বন্ধ বা বাতিল করার অধিকার সংরক্ষণ করে।`,
  activity_rules_6: `৬. ফ্রি গেম সংরক্ষণ করবেন না, ফ্রি গেম কিনুন।`,
  activity_rules_7: `৭. কোনও বোনাসের সাথে মিলিত করা যাবে না।`,
  activity_rules_8: `৮. প্রতি জমার সর্বাধিক তোলা R$100,000।`,
  event_instructions: 'ঘটনার নির্দেশিকা',
  agent_p1: 'আপনার অনন্য লিংক বিভিন্ন অ্যাপ এবং সোশ্যাল মিডিয়ায় শেয়ার করুন',
  agent_p2:
    'ফেসবুক, ইনস্টাগ্রাম, টিকটোক, টেলিগ্রাম, হোয়াটসঅ্যাপ, ইউটিউব, ইত্যাদির মাধ্যমে, তাড়াতাড়ি সবাইকে আমন্ত্রণ জানান, আপনার এক্সক্লুসিভ লিংকের মাধ্যমে নিবন্ধন করা সদস্যরা আপনার সরাসরি এজেন্ট হয়ে যাবে। কিন্তু খেয়াল রাখুন, কারণ আপনি যে নতুন সদস্যদের আমন্ত্রণ জানাচ্ছেন তারা সত্যিকারের এবং বৈধ হতে হবে।',
  agent_step1:
    'আমন্ত্রিত বন্ধু প্রথম জমা সফল হলে, আপনি R$5 বোনাস জিততে পারেন। উদাহরণস্বরূপ, যদি আপনি ১০ জনকে প্রথম জমার জন্য আমন্ত্রণ জানান, আপনি R$50 উপার্জন করতে পারেন। আপনি যত বেশি লোককে আমন্ত্রণ জানাবেন, তত বেশি বোনাস পাবেন। আপনি আপনার ইতিহাস দেখতে এবং প্রচার পৃষ্ঠায় নিবন্ধন করতে পারেন।',
  agent_step2:
    'যদি আপনার অতিথিরা R$1 বা তার বেশি বেট করে, আপনি ইতিমধ্যেই উচ্চ কমিশন উপভোগ করতে পারেন! আপনি জীবনের জন্য ৫% পর্যন্ত কমিশন উপার্জন করতে পারেন, এবং আমন্ত্রণ চিরকাল থাকবে! সিস্টেমটি আগের দিনের কমিশন পরের দিনের সকালে ৭টায় সেটল করবে।',
  agent_step2_1: 'কিভাবে পাবেন: লগইন - আমন্ত্রণ প্রচার - কমিশন সংগ্রহ করুন',
  agent_table1: 'স্লট, মাছ ধরা এবং ব্লকচেইনে বেটিং কমিশনের হার নিম্নরূপ:',
  agent_table1_1: 'গ্রাহক ২০ টাকা ২০০ বেট করে',
  person: 'ব্যক্তি',
  agent_table2_title1: 'সদস্য বেটিং',
  agent_table2_title2: 'শুধুমাত্র স্লট গেম',
  agent_table2_example:
    'উদাহরণ: আপনার ১০০ সদস্য রয়েছে, এবং বেটের মোট বাস্তব মূল্য দৈনিক ১ মিলিয়ন রেয়াস হিসেবে অনুমান করা হয়েছে। আপনি সেই দিন যে কমিশন পাবেন তা হবে: ১,০০০,০০০ x ১.২০% = ১২,০০০ রেয়াস। ১০০,০০০ মাসিক আয় অর্জন করা খুব সহজ।',
  reminder: 'স্মরণ',
  agent_reminder:
    'একই IP, একই ডিভাইস, নাম, ব্যাংক কার্ড এবং অন্যান্য তথ্য সহ একটি সদস্য অ্যাকাউন্ট শুধুমাত্র একবার অংশগ্রহণ করতে পারে, এবং যে কেউ নিয়ম ভঙ্গ করবে তারা এই বোনাস থেকে উপকৃত হবে না। অথবা গোষ্ঠীটি বাজেয়াপ্ত করা হবে এবং অ্যাকাউন্টগুলি স্থায়ীভাবে বরফে রাখা হবে।',
  agent_step3_1:
    'সদস্যদের কার্যক্রমে অংশগ্রহণ স্বয়ংক্রিয়ভাবে সিস্টেম দ্বারা গণনা করা হয়, যদি কোনও আপত্তি থাকে, তবে ড্রিমউইন75 দ্বারা পরিচালিত বিশ্লেষণের ফলাফল প্রাধান্য পাবে।',
  agent_step3_2:
    'যদি আপনি আপনার সদস্য অ্যাকাউন্ট/পাসওয়ার্ড ভুলে যান, তাহলে দয়া করে আমাদের ২৪ ঘণ্টার গ্রাহক পরিষেবা দলের সাথে যোগাযোগ করুন আপনার অ্যাকাউন্ট তথ্য পুনরুদ্ধার করতে সাহায্য করার জন্য।',
  agent_step3_3: 'ড্রিমউইন75 যেকোন সময় প্রচার পরিবর্তন, বিঘ্নিত বা বাতিল করার অধিকার সংরক্ষণ করে।',
  agent_step3_4: 'এজেন্ট কমিশন বোনাস নগদ বা ওয়ালেটে ক্রেডিটের শর্তে গ্রহণ করতে সক্ষম।',
  agent_step3_5: 'ফ্রি গেম সংরক্ষণ করবেন না, ফ্রি গেম কিনুন।',
  agent_step3_6: 'কোনও বোনাস এবং জমার সাথে মিলিত করা যাবে না।',
  agent_step3_7: 'শুধুমাত্র স্লট গেমের জন্য উপলব্ধ।',
  promo_concluded: 'প্রচার শেষ হয়েছে! আবার কাল চেক করুন!',
  can_only_claim: 'বোনাস {{x}} থেকে {{y}} ব্রাজিল সময়ের মধ্যে শুধুমাত্র দাবি করা যাবে',
  please_login: 'বোনাস দাবি করার জন্য আগে লগইন করুন!',
  back_to_home: 'হোমে ফিরে যান',
  bonus_claimed: 'বোনাস ইতিমধ্যে দাবি করা হয়েছে!',
  terms_n_conditions: `শর্তাবলী`,
  claim_now: `এখন দাবি করুন`,
  the_activity_x_in: `ক্রিয়াকলাপ {{x}} মধ্যে`,
  starts: `শুরু`,
  ends: `শেষ`,
  list_of_winners: `জয়ীদের তালিকা`,
  days: `দিন`,
  hours: `ঘণ্টা`,
  minutes: `মিনিট`,
  seconds: `সেকেন্ড`,
  total_amount_received: `মোট পরিমাণ গ্রহণ করা হয়েছে`,
  deposit_fee: `জমা ফি`,
  get: 'পাওয়া',
  apply_now: `এখন আবেদন করুন`,
  number_exists: 'এই ফোন নম্বর ইতিমধ্যে নিবন্ধিত!',
  number_not_exists: 'এই ফোন নম্বর নিবন্ধিত নয়!',
  payment_draft:
    'যদি আপনি নিশ্চিত করেন যে আপনি ইতিমধ্যে অর্থ প্রদান করেছেন, তবে দয়া করে কিছুক্ষণ অপেক্ষা করুন কারণ অর্থপ্রদান এখনও প্রক্রিয়াধীন থাকতে পারে।',
  my_downline: 'আমার ডাউনলাইন',
  downline_name: 'ডাউনলাইন নাম',
  date_joined: 'জানার তারিখ',
  deposit_amount_x_days: 'জমা পরিমাণ ({{x}} দিন)',
  dont_show_again: 'আবার দেখাবেন না',
  next: 'পরবর্তী',
  close: 'বন্ধ করুন',
  no_balance: 'কোন ব্যালেন্স নেই',
  menu: `মেনু`,
  vip_club: `ভিআইপি ক্লাব`,
  home: `হোম`,
  lobby: `লবি`,
  show_all: `সব দেখান`,
  game_name: `গেমের নাম`,
  sub_filters: `উপ-ফিল্টার`,
  contact_us_24: `২৪ ঘণ্টার সেবায় আমাদের সাথে যোগাযোগ করুন`,
  live_chat: `লাইভ চ্যাট`,
  chat_now: `এখন চ্যাট করুন`,
  gaming_license: `গেমিং লাইসেন্স`,
  certification: `সার্টিফিকেশন`,
  security: `নিরাপত্তা`,
  threatmetrix: `থ্রেটমেট্রিক্স`,
  gaming_license_caption: `ড্রিমউইন৭৫ একটি লাইসেন্সপ্রাপ্ত এবং নিয়ন্ত্রিত প্রতিষ্ঠান, যা কুরাকাওয়ের সরকারের দ্বারা পরিচালিত এবং গেমিং সার্ভিসেস প্রোভাইডার, এনভি #৩৬৫/জেএজের মাস্টার লাইসেন্সের অধীনে আইনগতভাবে কাজ করে।`,
  no_limits: `কোন সীমা নেই`,
  vip_description_no_limits: `আমরা ভিআইপি ক্লাবে সমস্ত খেলোয়াড়দের জন্য অনন্য গেমিং শর্তাবলী সহ ব্যক্তিগত ভিআইপি সেবা প্রদান করি। এখন আপনি কোনও চিন্তা ছাড়াই খেলতে পারেন! এটি খুব সহজ - রোমাঞ্চের জন্য খেলুন, আপনার গেমিং দক্ষতা উন্নত করুন, সময় উপভোগ করুন এবং আপনার জয়ের সুযোগে কোন সীমা রাখবেন না!`,
  financial_privileges: `আর্থিক সুবিধা`,
  vip_description_financial_privileges: `ভিআইপি ক্লাব উচ্চমানের ভিআইপি সেবা পেতে আগ্রহী সকল খেলোয়াড়কে স্বাগতম জানায়। বিশেষ সুবিধা, বিশেষ প্রচার এবং উপকারিতা আমাদের ওয়েবসাইটে আপনার জন্য উপলব্ধ! ভিআইপি খেলোয়াড়ের স্ট্যাটাস আপনাকে রেকর্ড-ভাঙা সংক্ষিপ্ত সময়ে তহবিল উত্তোলনের উচ্চতর সীমা আনলক করে! উপভোগ করুন!`,
  personal_manager: `ব্যক্তিগত ম্যানেজার`,
  vip_description_personal_manager: `আপনি যখন একটি ভিআইপি খেলোয়াড় হন তখন কোন সুবিধাগুলি পেতে চান? আপনার ব্যক্তিগত ম্যানেজারের সাথে আপনার চিন্তাভাবনা শেয়ার করুন এবং আমাদের জানাবেন যে আমরা আমাদের ওয়েবসাইটে আরও কি দেখতে খুশি হব! আমরা সর্বদা দুর্দান্ত পরামর্শের জন্য উন্মুক্ত!`,
  vip_promotions: `ভিআইপি প্রচার`,
  vip_description_vip_promotions: `আপনি কি আরও দারুণ প্রচার পেতে চান? বিপুল পরিমাণ ভিআইপি খেলোয়াড়রা এই সমস্ত এবং আরও অনেক সুবিধা উপভোগ করেন তাদের বিশ্বস্ততার পুরস্কার হিসেবে! তাদের একজন হন!`,
  withdraw_limit: `উত্তোলনের সীমা`,
  monthly: `মাসিক`,
  weekly: `সাপ্তাহিক`,
  annual: `বার্ষিক`,
  cashback: `ক্যাশব্যাক`,
  personal_account_manager: `ব্যক্তিগত অ্যাকাউন্ট ম্যানেজার`,
  yes: `হ্যাঁ`,
  join_the_vip: `এখনই ভিআইপি প্রোগ্রামে যোগ দিন!`,
  join_now: `এখন যোগ দিন`,
  play_the_way_you_want: `আপনার ইচ্ছে মত খেলুন`,
  more_info: `আরও তথ্য`,
  nothing_to_show: `দেখানোর জন্য কিছু নেই`,
  remarks: `মন্তব্য`,
  password: `পাসওয়ার্ড`,
  back: `পেছনে`,
  new_to_dreamwin75: `ড্রিমউইন75-এ নতুন`,
  create_an_account: `একটি অ্যাকাউন্ট তৈরি করুন`,
  withdraw_amount: `টাকা তোলার পরিমাণ`,
  balance: `ব্যালেন্স`,
  bank_bsb: `ব্যাংক BSB`,
  enter_bank_branch: `ব্যাংক শাখা লিখুন`,
  enter_pay_id: `পে আইডি লিখুন`,
  level: `স্তর`,
  claimed: `দাবি করেছে`,
  not_found: `পাওয়া যায়নি`,
  become_a_vip: `একজন ভিআইপি হয়ে উঠুন`,
  otp_not_found: `OTP সেটআপ নয়`,
  resend_again: `আবার পাঠান`,
  how_to_get_referral_bonus: 'রেফারেল ফ্রেন্ড বোনাস কীভাবে পাবেন?',
  steps_to_claim: 'বোনাস পাওয়ার পদক্ষেপসমূহ',
  share_your_referral_code:
    '1. ফেসবুক, টুইটার, বা হোয়াটসঅ্যাপ এর মাধ্যমে আপনার রেফারেল কোড শেয়ার করুন যাতে আপনার বন্ধু অ্যাকাউন্ট রেজিস্টার করে।',
  after_friend_register:
    '2. আপনার বন্ধু DreamWin-এ রেজিস্টার করার পর, উভয় রেফারেল এবং রেফারেল সদস্যকে বোনাস পাওয়ার জন্য ডিপোজিট করতে হবে।',
  refer_a_friend_bonus: '3. বন্ধুকে রেফার করা বোনাসের গণনা:',
  turnover_played: 'খেলায় টার্নওভার x কমিশন % = রেফারেল ফ্রেন্ড বোনাস',
  example: 'উদাহরণ:',
  turnover_formula_x: 'টার্নওভার (1,000,000) x 0.002% (কমিশন %) = 2,000{{x}}',
  note_bonus_only: '*নোট: বোনাস শুধুমাত্র পরের দিন 12:00am এর পরে দাবি করা যাবে',
  note_bonus_only_x: '*নোট: বোনাস শুধুমাত্র পরের দিন {{x}} এর পরে দাবি করা যাবে',
  access_dashboard: '4. ড্যাশবোর্ডে প্রবেশ করে প্রাপ্য মুনাফা, কমিশন স্তর, দাবি ইতিহাস এবং ডাউনলাইন চেক করুন।',
  click_this_button_to_claim: 'রেফারেল ফ্রেন্ড বোনাস দাবি করতে এই বোতামে ক্লিক করুন',
  commision_tier: 'কমিশন স্তর',
  member_downline: 'সদস্য ডাউনলাইন',
};

export default en;
