import { useEffect, useState } from 'react';
import { Box, Divider, Grid, IconButton, Stack, Tab, Typography, styled } from '@mui/material';
import TelegramLoginButton from 'react-telegram-login';
import { useSnackbar } from 'notistack';
import { LoginSocialFacebook } from 'reactjs-social-login';
// Tabs
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import useLocales from '../../../../hooks/useLocales';

import { useDispatch, useSelector } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';

import Login from './Login';
import Register from './Register';
import CustomModal from '../../../../components/CustomModal';
import Image from '../../../../components/Image';
import conObj from '../../../../utils/connection-assistant';
import useAuth from '../../../../hooks/useAuth';

import palette from '../../../../theme/palette';
import { telegramId } from '../../../../config';
import useResponsive from '../../../../hooks/useResponsive';
import Iconify from '../../../../components/Iconify';

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'sans-serif',
  fontWeight: 500,
  color: palette.visitor.dialog.footerText,
  cursor: 'pointer',
}));

const SocialIconCon = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 'fit-content !important',
  display: 'flex !important',
  alignItems: 'center !important',
  justifyContent: 'center !important',
  cursor: 'pointer !important',
}));

const ContainerHeader = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  bottom: '3.5rem',
  display: 'flex',
  padding: '0 1rem',
}));

const ContainerHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '0.2rem',
  marginLeft: '0.2rem',
}));

const CustomHr = ({ style }) => (
  <hr style={{ borderTop: `0.03rem solid ${palette.visitor.dialog.hr}`, borderBottom: 'none', height: 1, ...style }} />
);

const SocialIcon = styled(Image)(({ theme }) => ({
  width: '35px',
  height: '35px',
  borderRadius: '5px',
  cursor: 'pointer',
}));

const LoginSignupDialog = ({ setOpenForgotPasswordDialog }) => {
  const { login } = useAuth();

  const dispatch = useDispatch();

  const { isOpenLoginSignupDialog } = useSelector((x) => x.authPages);

  const [activeTab, setActiveTab] = useState('1');

  const [authData, setAuthData] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const isSm = useResponsive('down', 'lg');

  useEffect(() => {
    if (isOpenLoginSignupDialog?.isLogin) setActiveTab('1');
    if (!isOpenLoginSignupDialog?.isLogin) setActiveTab('2');
  }, [isOpenLoginSignupDialog]);

  const handleTelegramLogin = () => {
    window?.Telegram?.Login.auth({ bot_id: telegramId, request_access: true }, (data) => {
      if (!data) {
        // authorization failed
      }

      // Here you would want to validate data like described there https://core.telegram.org/widgets/login#checking-authorization
      thirdPartyAuth(data);
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const thirdPartyAuth = async (response, type = 'telegram') => {
    try {
      const apiResponse = await conObj.post('users/tpp_login.json', {
        'user_third_party_login-data': JSON.stringify(response),
        'user_third_party_login-platform': type,
      });

      let isSuccess = false;

      const responseData = apiResponse?.data?.data;

      console.log(response, responseData?.registerNow);

      if (responseData?.registerNow) {
        setAuthData({ ...response, platform: type });
        setActiveTab('2');
        isSuccess = true;
      }

      if (!responseData?.registerNow && responseData?.token) {
        await login(
          {},
          {
            token: responseData?.token,
            firstName: responseData?.first_name,
            username: responseData?.username,
            fullName: responseData?.full_name,
            lastName: responseData?.last_name,
            email: responseData?.email,
          }
        );
        isSuccess = true;
      }

      if (!isSuccess) {
        throw new Error(`Login to ${type} failed!`);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  return (
    <CustomModal
      // fullScreen={isSm}
      hasClose={false}
      maxWidth={'xs'}
      PaperProps={{ sx: { backgroundColor: 'transparent', borderRadius: '0px !important', p: '12px' } }}
      open={isOpenLoginSignupDialog.open}
      onClose={() => dispatch(openLoginSignup({ open: false, isLogin: true }))}
    >
      <Image
        src={require('../../../../assets/visitor/close.png')}
        onClick={() => dispatch(openLoginSignup({ open: false, isLogin: true }))}
        sx={{ width: '25px', height: '25px', position: 'absolute', right: 0, top: 0, zIndex: 1, cursor: 'pointer' }}
      />
      <TabContext value={activeTab}>
        <TabList
          onChange={handleTabChange}
          aria-label="lab API tabs example"
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
        >
          <Tab
            label={translate('login')}
            value="1"
            sx={{
              borderTopLeftRadius: '15px',
              borderTopRightRadius: '15px',
              background: activeTab === '1' ? '#5C00C7' : '#52008B',
              flex: 1,
              m: '0px !important',
              textShadow: '0px 1px 2px rgba(0, 0, 0, .8)',
            }}
          />
          <Tab
            label={translate('register')}
            value="2"
            sx={{
              borderTopLeftRadius: '15px',
              borderTopRightRadius: '15px',
              background: activeTab === '2' ? '#5C00C7' : '#52008B',
              flex: 1,
              textShadow: '0px 1px 2px rgba(0, 0, 0, .8)',
            }}
          />
        </TabList>
        <Box
          sx={{
            background: '#5C00C7',
            p: '32px 24px',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          }}
        >
          <TabPanel value="1">
            <Login
              setOpenForgotPasswordDialog={setOpenForgotPasswordDialog}
              setActiveTab={setActiveTab}
              setAuthData={setAuthData}
            />
          </TabPanel>
          <TabPanel value="2">
            <Register setActiveTab={setActiveTab} authData={authData} />
          </TabPanel>
        </Box>
      </TabContext>

      {/* <Box sx={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
        <Image
          src={
            isOpenLoginSignupDialog.isLogin
              ? require('../../../../assets/visitor/visitor_banner_mobile.png')
              : require('../../../../assets/visitor/visitor_banner_mobile_register.png')
          }
        />
        <ContainerHeader
          sx={{
            justifyContent: isOpenLoginSignupDialog.isLogin ? 'end' : 'start',
          }}
        >
          <ContainerHeaderWrapper
            sx={{
              alignItems: isOpenLoginSignupDialog.isLogin ? 'end' : 'start',
            }}
          >
            <Typography sx={{ fontSize: '2rem', fontWeight: 700, textTransform: 'uppercase' }}>
              {translate(isOpenLoginSignupDialog.isLogin ? 'Login' : 'Register')}
            </Typography>
            <Box sx={{ width: '10rem', mr: '-1rem', overflow: 'hidden' }}>
              <Image src={require('../../../../assets/brand-logo.png')} sx={{ width: '100%' }} />
            </Box>
          </ContainerHeaderWrapper>
        </ContainerHeader>
      </Box> */}
      {/* <Box
        sx={{
          overflow: 'hidden',
          color: 'white',
          mt: '-3rem',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            borderTopRightRadius: '1rem',
            borderTopLeftRadius: '1rem',
            width: '100%',
            height: '3.4rem',
            overflow: 'hidden',
            backgroundColor: 'rgba(37, 38, 49, 0.8)',
            WebkitBackdropFilter: 'blur(4px)',
            backdropFilter: 'blur(4px)',
          }}
        />
        
        <Box sx={{ px: 3, pb: 3 }}>
          {activeTab === '1' ? (
            <Login
              setOpenForgotPasswordDialog={setOpenForgotPasswordDialog}
              setActiveTab={setActiveTab}
              setAuthData={setAuthData}
            />
          ) : (
            <Register setActiveTab={setActiveTab} authData={authData} />
          )}
        </Box>
      </Box> */}
    </CustomModal>
  );
};

export default LoginSignupDialog;
