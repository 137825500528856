import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useDispatch, useSelector } from '../../../redux/store';
import CustomModal from '../../../components/CustomModal';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';
import Deposit from './deposit/Deposit';
import Withdraw from './withdraw/Withdraw';
import useLocales from '../../../hooks/useLocales';
import { TitleBar } from '../../GlobalStyles';

const TransactionDialog = () => {
  const { isOpenTransactionsDialog } = useSelector((x) => x.transactionsDialog);

  const { translate } = useLocales();

  const dispatch = useDispatch();

  return (
    <CustomModal
      open={isOpenTransactionsDialog.open}
      onClose={() => dispatch(openTransactionsDialog({ open: false, isDeposit: true }))}
      hasClose={false}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <TitleBar>
          <Typography>{translate(isOpenTransactionsDialog?.isDeposit ? 'deposit' : 'withdraw')}</Typography>
        </TitleBar>
      </Box>
      <Box sx={{ p: { xs: 1, sm: 1, md: 2 } }}>{isOpenTransactionsDialog.isDeposit ? <Deposit /> : <Withdraw />}</Box>
    </CustomModal>
  );
};

export default TransactionDialog;
