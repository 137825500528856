import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, InputBase, Modal, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import Iconify from '../../../../components/Iconify';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import CustomModal from '../../../../components/CustomModal';
import { ThemeButton, TitleBar } from '../../../GlobalStyles';

const snackbarOptionsSuccess = { variant: 'success', autoHideDuration: 5000 };
const snackbarOptionsError = { variant: 'error', autoHideDuration: 5000 };

const ForgotPasswordDialog = ({ openForgotPasswordDialog, setOpenForgotPasswordDialog }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const [email, setEmail] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = () => {
    if (!email.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
      enqueueSnackbar(translate('enter_valid_email'), snackbarOptionsError);
      return;
    }

    setIsSubmitting(true);
    conObj
      .post(`forgot.json`, { 'user-email': email.trim() })
      .then((res) => {
        if (res.status === 200) {
          const { msg, status } = res.data?.data;
          if (status === 1) {
            enqueueSnackbar(translate('email_sent'), snackbarOptionsSuccess);
          }
        }
      })
      .catch((err) => {
        const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
        enqueueSnackbar(err.message, snackbarOptions);
        console.log(`Server Error: ${err.message}`);
      })
      .then(() => {
        setOpenForgotPasswordDialog(false);
        setIsSubmitting(false);
      });
  };

  return (
    <CustomModal
      open={openForgotPasswordDialog}
      onClose={() => setOpenForgotPasswordDialog(false)}
      sx={{ overflow: 'hidden' }}
      hasClose={false}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <TitleBar>
          <Typography fontWeight={700}>{translate('forgot_password')}</Typography>
        </TitleBar>
      </Box>
      <Box
        sx={{
          p: 1,
        }}
      >
        {/* <Iconify
          icon={'ep:close'}
          width={24}
          height={24}
          sx={{ cursor: 'pointer', position: 'absolute', right: 16, top: 16, color: 'rgb(147, 172, 211)' }}
          onClick={() => setOpenForgotPasswordDialog(false)}
        /> */}

        <Box>
          <Typography fontWeight={700}>{translate('please_enter_email')}</Typography>
          <Box sx={{ mb: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                borderRadius: '10px',
                border: '1px solid #D9D0F1',
                height: '44px',
                p: '8.5px 13px',
                backgroundColor: '#120033',
              }}
            >
              <Iconify icon={'mdi-light:email'} width={24} height={24} sx={{ color: '#D9D0F1' }} />
              <InputBase
                name="email"
                value={email}
                placeholder={translate('email')}
                //   InputLabelProps={{
                //     style: { color: '#999999' },
                //  }}
                sx={{
                  ml: 1,
                  fontSize: '14px',
                  fontWeight: 600,
                  fontFamily: 'sans-serif',
                  flexGrow: 1,
                  color: 'white',
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Stack>
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ borderColor: '#D9D0F1', color: '#D9D0F1' }}
                onClick={() => setOpenForgotPasswordDialog(false)}
              >
                {translate('cancel')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <ThemeButton fullWidth loading={isSubmitting} disabled={isSubmitting} onClick={() => onSubmit()}>
                {translate(`confirm`)}
              </ThemeButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </CustomModal>
  );
};

ForgotPasswordDialog.propTypes = {
  openForgotPasswordDialog: PropTypes.bool,
  setOpenForgotPasswordDialog: PropTypes.func,
};

export default ForgotPasswordDialog;
