import React, { useEffect, useState } from 'react';
// @mui
import { Box, MenuItem, Stack, Typography } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { useSelector } from '../../../redux/store';

// ----------------------------------------------------------------------

export default function LanguagePopover({ sx = {}, CustomBtn, PopoverProps = {}, isSideNav = false }) {
  const { allLangs, currentLang, onChangeLang, translate } = useLocales();

  const { siteSettings } = useSelector((x) => x.lookup);

  const [customLangs, setCustomLangs] = useState([]);

  const [open, setOpen] = useState(null);

  const [selectedLang, setSelectedLang] = useState(currentLang);

  const domainLocale = process.env.REACT_APP_LOCALE;

  const langStorage = localStorage.getItem('i18nextLng');

  useEffect(() => {
    // setCustomLangs(allLangs.filter((f) => f.value === 'en' || f.value === domainLocale));
    if (siteSettings?.languages?.length > 0) {
      const matchingLangs = allLangs;
      // I JUST COMMENT THIS TEMPORARILY
      // .filter((obj1) =>
      //   siteSettings?.languages.some((obj2) => obj1.value === obj2.locale)
      // );

      if (matchingLangs) {
        setCustomLangs(matchingLangs);

        if (matchingLangs.findIndex((ml) => ml.value === langStorage) === -1) {
          handleChangeLang(matchingLangs.find((f) => f.value === domainLocale)?.value);
          console.log('matching');
        }
      } else {
        console.log('No Matching languages');
      }
    }
  }, [siteSettings?.languages]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang) => {
    // localStorage.setItem('i18nextLng', newLang);
    onChangeLang(newLang);
    handleClose();
    window.location.reload();
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(e) => handleOpen(e)}>
        <Stack sx={{ flex: 1 }} direction={'row'} spacing={2}>
          {
            isSideNav ? (
              <Stack direction={'row'} alignItems={'center'} spacing={2} sx={{ cursor: 'pointer', width: '100%' }}>
                <Image
                  disabledEffect
                  src={selectedLang.icon}
                  alt={selectedLang.label}
                  sx={{
                    width: 25,
                    height: 25,
                    borderRadius: '50%',
                  }}
                />
                <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#fff', flex: 1, whiteSpace: 'nowrap' }}>
                  {translate('change_language')}
                </Typography>
                <Image src={require('../../../assets/navbar/arrow.png')} sx={{ width: 20, height: 20 }} />
              </Stack>
            ) : (
              // <Typography
              //   sx={{
              //     fontSize: '13px',
              //     fontWeight: 900,
              //     fontFamily: 'sans-serif',
              //     lineHeight: '1.5',
              //     textDecoration: 'none',
              //     marginLeft: '16px',
              //   }}
              // >
              //   {selectedLang?.label}
              // </Typography>
              // CustomBtn ? (
              //   React.cloneElement(CustomBtn, { title: selectedLang?.label })
              // ) : (
              <Image
                disabledEffect
                src={selectedLang.icon}
                alt={selectedLang.label}
                sx={{ width: isSideNav ? 21 : 22, height: isSideNav ? 21 : 22, borderRadius: '50%' }}
              />
            )
            // )
          }
        </Stack>
      </Box>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        keepMounted
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
          background: '#371596',
        }}
        {...PopoverProps}
      >
        <Stack spacing={0.75}>
          {customLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                handleChangeLang(option.value);
                setSelectedLang(option);
              }}
            >
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
