import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import Slider from 'react-slick';
import { addDays, format, isAfter } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from '../../redux/store';
import CustomModal from '../../components/CustomModal';
import Image from '../../components/Image';
import useLocales from '../../hooks/useLocales';
import conObj from '../../utils/connection-assistant';
import { CarouselDots } from '../../components/carousel';
import useAuth from '../../hooks/useAuth';
import { openLoginSignup } from '../../redux/slices/authPages';
import { calculateFontSize } from '../../utils/formatString';

const ModalBox = styled(Box)(({ theme }) => ({
  padding: '0rem 1rem 1rem 1rem',
}));

const TitleText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 20,
  fontWeight: 600,
  color: '#dfdfdf',
  [theme.breakpoints.only('xs')]: {
    fontSize: '3.35vw',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
}));

const SubTitleText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 15,
  fontWeight: 600,
  color: '#dfdfdf',
  [theme.breakpoints.only('xs')]: {
    fontSize: '2.5vw',
  },
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 15,
  color: '#dfdfdf',
  paddingBottom: '1rem',
  [theme.breakpoints.only('xs')]: {
    fontSize: '2.5vw',
  },
}));

const ClaimButton = styled(LoadingButton)(({ theme }) => ({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '1rem',
  // background: '#f2c556',
  color: '#fff',
  '&:hover': {
    background: '#f2c556',
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: '2.5vw',
  },
}));

export const popoverZindex = 2147483648;

const PopupBanner = () => {
  const { translate } = useLocales();

  const { siteSettings } = useSelector((x) => ({ ...x.lookup, ...x.authPages }));

  const [openPopup, setOpenPopup] = useState(false);

  const carouselRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [dontShowAgain, setDontShowAgain] = useState(false);

  const popups = useMemo(() => {
    if (siteSettings?.popups?.length > 0) {
      return [...siteSettings?.popups].reverse();
    }
    return [];
  }, [siteSettings]);

  useEffect(() => {
    if (popups?.length) {
      const duration = localStorage.getItem('showPopupTime');

      let isExpired = false;

      if (duration) {
        isExpired = isAfter(new Date(), addDays(new Date(duration), 1));
      }

      if (!duration || isExpired) setOpenPopup(true);
    }
  }, [popups]);

  const handlePopupClose = () => {
    setOpenPopup(false);
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    if (currentIndex === popups?.length - 1) handlePopupClose();
    else carouselRef.current?.slickNext();
  };
  const handleDontShowAgain = () => {
    if (dontShowAgain === false) {
      localStorage.setItem('showPopupTime', format(new Date(), 'MM-dd-yyyy HH:mm:ss'));
    } else localStorage.removeItem('showPopupTime');
    setDontShowAgain(!dontShowAgain);
  };

  const settings = {
    speed: 800,
    dots: true,
    arrows: false,
    autoplay: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    infinite: false,
    draggable: false,
    swipe: false,
    beforeChange: (current, next) => setCurrentIndex(next),
    ...CarouselDots({
      zIndex: 9,
      bottom: -5,
      position: 'absolute',
      width: '100%',
      color: '#D9D0F1',
    }),
  };

  return (
    <CustomModal open={openPopup} onClose={() => handlePopupClose()} maxWidth="sm" sx={{ zIndex: popoverZindex }}>
      <Box sx={{ p: 1 }}>
        <Box sx={{ background: 'none', '& .slick-active': { zIndex: 999 }, position: 'relative', pb: 1 }}>
          <Slider ref={carouselRef} {...settings}>
            {popups.map((data, index) => (
              <CarouselItem
                key={data?.id}
                popupBanner={data}
                isActive={index === currentIndex}
                handlePopupClose={handlePopupClose}
              />
            ))}
          </Slider>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="outlined"
            onClick={handlePrevious}
            sx={{
              opacity: currentIndex > 0 ? 1 : 0,
              fontSize: { xs: calculateFontSize(translate('Back'), 14, 4), md: '0.8rem' },
              minWidth: 'unset',
              borderColor: '#D9D0F1',
              color: '#D9D0F1',
            }}
          >
            {translate('Back')}
          </Button>
          <FormControlLabel
            value={dontShowAgain}
            onChange={handleDontShowAgain}
            sx={{ mr: 0 }}
            control={<Checkbox size="small" sx={{ padding: '0 3px 0 0' }} />}
            label={
              <Typography fontSize={{ xs: calculateFontSize(translate('dont_show_again'), 14, 16), md: '0.8rem' }}>
                {translate('dont_show_again')}
              </Typography>
            }
          />
          <Button
            variant="outlined"
            onClick={handleNext}
            sx={{
              fontSize: {
                xs: calculateFontSize(translate(currentIndex < popups?.length - 1 ? 'Next' : 'Close'), 14, 5),
                md: '0.8rem',
              },
              borderColor: '#D9D0F1',
              color: '#D9D0F1',
              minWidth: 'unset',
            }}
          >
            {translate(currentIndex < popups?.length - 1 ? 'Next' : 'Close')}
          </Button>
        </Box>
      </Box>
    </CustomModal>
  );
};

const CarouselItem = ({ popupBanner, handlePopupClose = () => {} }) => {
  const { isAuthenticated } = useAuth();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleClaimBonus = async () => {
    if (!isAuthenticated) {
      dispatch(openLoginSignup({ open: true, isLogin: true }));
      handlePopupClose();
      return;
    }

    setIsLoading(true);
    try {
      await conObj.post('transactions/manual_claim_bonus.json', {
        'transaction-promotion_id': popupBanner?.promotion_id,
      });

      enqueueSnackbar(translate('bonus_claimed'));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    } finally {
      setIsLoading(false);
      handlePopupClose();
    }
  };

  return (
    <>
      <ModalBox
        onClick={() =>
          popupBanner?.url
            ? window.open(
                popupBanner?.url,
                '_blank'
                // 'noopener noreferrer'
              )
            : {}
        }
      >
        <TitleText>{popupBanner?.title}</TitleText>

        {popupBanner?.subtitle && <SubTitleText>{popupBanner?.subtitle}</SubTitleText>}

        <Box sx={{ my: 2 }}>
          {popupBanner?.banner && (
            <Image
              src={popupBanner?.banner}
              alt="desktop"
              ratio={'6/9'}
              sx={{
                display: popupBanner?.banner_mobile ? { sm: 'block', xs: 'none' } : { display: 'block' },
                borderRadius: '10px',
                cursor: 'pointer',
                // width: { md: '57vw', sm: '81vw', xs: '81vw' },
                // height: { md: '23vw', sm: '34vw', xs: '34vw' },
                // maxHeight: '212px',
                // maxWidth: '512px',
              }}
            />
          )}
          {popupBanner?.banner_mobile && (
            <Image
              src={popupBanner?.banner_mobile}
              alt="mobile"
              ratio={'6/9'}
              sx={{
                display: popupBanner?.banner ? { sm: 'none', xs: 'block' } : { display: 'block' },
                borderRadius: '10px',
                // width: { md: '57vw', sm: '81vw', xs: '81vw' },
                // height: { md: '23vw', sm: '34vw', xs: '34vw' },
                // maxHeight: '212px',
                // maxWidth: '512px',
              }}
            />
          )}
        </Box>

        {popupBanner?.description && <DescriptionText>{popupBanner?.description}</DescriptionText>}
      </ModalBox>
      {popupBanner?.is_manual_claimable && (
        <ClaimButton
          variant="contained"
          loading={isLoading}
          onClick={() => handleClaimBonus()}
          disabled={!popupBanner?.is_claimable_now}
        >
          {translate('claim')}
        </ClaimButton>
      )}
    </>
  );
};

export default PopupBanner;
