// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const th = {
  games: `เกมส์`,
  game_history: `ประวัติเกม`,
  bet_amount: `จำนวนเงินเดิมพัน`,
  login: `เข้าสู่ระบบ`,
  register: `ลงทะเบียน`,
  username_placeholder: `ชื่อผู้ใช้`,
  please_input_your_x: `กรุณากรอก {{x}} ของคุณ`,
  verification_code: `รหัสยืนยัน`,
  press_get_code: `กดปุ่มรับรหัสเพื่อรับรหัสยืนยันทาง SMS`,
  otp_sent: `ส่งรหัสยืนยันแล้ว! ป้อนรหัสยืนยันด้านล่างเพื่อดำเนินการต่อ`,
  your_otp_expire: `OTP ของคุณจะหมดอายุภายใน`,
  get_code: `รับรหัส`,
  didnt_receive: `ไม่ได้รับข้อความใช่ไหม?`,
  send_again: `ส่งอีกครั้ง`,
  captcha: `CAPTCHA`,
  forgot_password: `ลืมรหัสผ่าน`,
  minor_note: `ในการเยี่ยมชมเว็บไซต์นี้ โปรดตรวจสอบให้แน่ใจว่าคุณมีอายุเกิน 18 ปี และยอมรับ`,
  terms_of_service: `ข้อกำหนดการให้บริการ`,
  by_signing_in: `เมื่อเข้าสู่ระบบหรือสมัครสมาชิก คุณตกลงยอมรับ`,
  dont_have_account: `ยังไม่มีบัญชีใช่ไหม?`,
  sign_up: `สมัครสมาชิก`,
  username: `ชื่อผู้ใช้`,
  email: `อีเมล`,
  full_name: `ชื่อเต็ม`,
  confirm_18: `ฉันยืนยันว่าฉันอายุ 18 ปี และฉันได้อ่าน`,
  already_have_account: `มีบัญชีแล้วใช่ไหม?`,
  deposit: `ฝากเงิน`,
  bonus: `โบนัส`,
  load_more: `โหลดเพิ่มเติม`,
  responsible_gaming: `การเล่นเกมอย่างมีความรับผิดชอบ`,
  faq: `คำถามที่พบบ่อย`,
  amount: `จำนวน`,
  extra: `พิเศษ`,
  deposit_bonus: `โบนัสเงินฝาก`,
  withdraw: `ถอนเงิน`,
  min: `ขั้นต่ำ:`,
  max: `สูงสุด:`,
  notifications: `การแจ้งเตือน`,
  invalid_x: `{{x}} ไม่ถูกต้อง`,
  captcha_not_match: `Captcha ไม่ตรงกัน`,
  invalid_x_must_be_atleast_y_characters: `{{x}} ไม่ถูกต้อง ต้องมีอย่างน้อย {{y}} ตัวอักษร`,
  x_is_required: `{{x}} จำเป็น`,
  phone_number: `หมายเลขโทรศัพท์`,
  phone_num_x_max: `หมายเลขโทรศัพท์ต้องมีไม่เกิน {{x}} หลัก`,
  email_sent: `ส่งอีเมลแล้ว โปรดตรวจสอบอีเมลของคุณ`,
  enter_valid_email: `โปรดกรอกอีเมลที่ถูกต้อง`,
  confirm: `ยืนยัน`,
  password_atleast_6_characters: `รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร`,
  password_reset_success: `รีเซ็ตรหัสผ่านสำเร็จ!`,
  new_password: `รหัสผ่านใหม่`,
  bank: `ธนาคาร`,
  bank_name: `ชื่อธนาคาร`,
  bank_branch: `สาขาธนาคาร`,
  pay_id: `Pay ID`,
  account_name: `ชื่อบัญชี`,
  account_number: `หมายเลขบัญชี`,
  drop_or_select: `วางหรือเลือกไฟล์`,
  drop_files_here: `วางไฟล์ที่นี่`,
  transfer_to: `โอนไปยัง`,
  upload_receipt: `อัปโหลดใบเสร็จ`,
  select_bonus: `เลือกโบนัส`,
  no_promotion: `ไม่มีโปรโมชั่น`,
  please_upload_receipt: `โปรดอัปโหลดใบเสร็จ`,
  bank_account: `บัญชีธนาคาร`,
  add_account: `เพิ่มบัญชี`,
  account_holder_name: `ชื่อเจ้าของบัญชี`,
  name_of_the_account_holder: `ชื่อของเจ้าของบัญชี`,
  fill_in_bank_account: `กรอกบัญชีธนาคาร`,
  withdraw_information: `ข้อมูลการถอน`,
  note: `หมายเหตุ`,
  amount_withdrawn: `จำนวนเงินที่ถอน`,
  amount_no_exceed_balance: `จำนวนการถอนต้องไม่เกินยอดเงินที่มีอยู่`,
  minimum_withdraw_amount_is_x: `จำนวนถอนขั้นต่ำคือ {{x}}`,
  transaction_history: `ประวัติธุรกรรม`,
  date_from: `วันที่เริ่มต้น`,
  date_to: `วันที่สิ้นสุด`,
  account_name_must_be_4: `ชื่อบัญชีต้องยาวกว่า 4 ตัวอักษร`,
  minimum_length_is_x: `ความยาวขั้นต่ำคือ {{x}}`,
  maximum_length_is_x: `ความยาวสูงสุดคือ {{x}}`,
  payment_method: `วิธีการชำระเงิน`,
  no_bank_account: `คุณไม่มีบัญชีสำหรับการถอนเงิน เพิ่มบัญชีได้ทันที`,
  bet_history: `ประวัติการเดิมพัน`,
  game_provider: `ผู้ให้บริการเกม`,
  bet_count: `จำนวนการเดิมพัน`,
  win_loss: `ชนะ/แพ้`,
  rebate: `เงินคืน`,
  turnover: `ยอดเทิร์นโอเวอร์`,
  game_category: `หมวดหมู่เกม`,
  you_have_x_unread_messages: `คุณมี {{x}} ข้อความที่ยังไม่ได้อ่าน`,
  deposit_amount_between_x_and_y: `จำนวนเงินฝากต้องอยู่ระหว่าง {{x}} และ {{y}}`,
  minimum_amount_is_x_y: `จำนวนเงินขั้นต่ำคือ {{x}} {{y}}`,
  all: `ทั้งหมด`,
  title: `ชื่อ`,
  date: `วันที่`,
  type: `พิมพ์`,
  status: `สถานะ`,
  remark: `หมายเหตุ`,
  category: `หมวดหมู่`,
  please_select_x: `โปรดเลือก {{x}}`,
  save: `บันทึก`,
  change_password: `เปลี่ยนรหัสผ่าน`,
  current_password: `รหัสผ่านปัจจุบัน`,
  confirm_password: `ยืนยันรหัสผ่านใหม่`,
  submit: `ส่ง`,
  password_changed: `เปลี่ยนรหัสผ่านแล้ว`,
  x_must_be_y_characters: `{{x}} ต้องมีอักขระอย่างน้อย {{y}} ตัว`,
  reset_password: `รีเซ็ตรหัสผ่าน`,
  slots: `สล็อต`,
  live_casino: `คาสิโนสด`,
  clear: `ชัดเจน`,
  search: `ค้นหา`,
  new: `ใหม่`,
  logout: `ออกจากระบบ`,
  choose_date: `เลือกวันที่`,
  select_both_date: `เลือกทั้งวันที่เริ่มต้นและถึง`,
  from: `จาก`,
  to: `ถึง`,
  apply: `นำมาใช้`,
  promotions: `โปรโมชั่น`,
  learn_more: `เรียนรู้เพิ่มเติม`,
  password_doesnt_match: `รหัสผ่านไม่ตรงกัน`,
  cancel: `ยกเลิก`,
  confirm_to_pay: `ยืนยันการชำระเงิน`,
  deposit_amount: `จำนวนเงินฝาก`,
  loading_please_wait: `กำลังโหลด กรุณารอสักครู่`,
  account: `บัญชี`,
  profile: `ประวัติโดยย่อ`,
  bank_list: `รายชื่อธนาคาร`,
  in_maintenance: `ในการบำรุงรักษา`,
  birthday: `วันเกิด`,
  birthday_bonus: `ป้อนวันเกิดของคุณเพื่อรับโบนัส! 🎉🎁`,
  referral_code: `รหัสอ้างอิง`,
  referral: `การอ้างอิง`,
  my_referral: `การอ้างอิงของฉัน`,
  my_claim: `การเรียกร้องของฉัน`,
  claim_profit: `เรียกร้องผลกำไร`,
  available_profit: `กำไรที่มีอยู่`,
  claimed_profit: `กำไรที่เรียกร้อง`,
  all_time_profit: `กำไรตลอดเวลา`,
  referral_note_on_x: `รับผลกำไรทุกครั้งที่เพื่อนของคุณเล่นบน {{x}}`,
  tier: `ชั้น`,
  commission: `คณะกรรมการ`,
  referred_member: `สมาชิกที่ได้รับการแนะนำ`,
  profit_earned: `กำไรที่ได้รับ`,
  account_information: `ข้อมูลบัญชี`,
  invite_friends: `เชิญเพื่อน`,
  how_it_work: `มันทำงานอย่างไร?`,
  invite_note: `รับโบนัสการแนะนำพิเศษเมื่อเพื่อนของคุณสมัครใช้รหัสอ้างอิงของคุณ!`,
  share_via: `แบ่งปันผ่านทาง`,
  referral_link: `ลิงค์ผู้อ้างอิง`,
  total_profit: `กำไรรวม`,
  copied: `คัดลอกแล้ว`,
  nothing_to_claim: `ไม่มีอะไรจะเรียกร้อง`,
  claim_history: `ประวัติการเรียกร้อง`,
  downline: `ดาวน์ไลน์`,
  last_x_digit_bank_ref_number: `{{x}} หลักสุดท้ายของหมายเลขอ้างอิงธนาคาร`,
  bank_ref_number_validation_x: `ต้องระบุหมายเลขอ้างอิงธนาคารและต้องมีความยาวอักขระ {{x}} ตัว`,
  login_directly: `เข้าสู่ระบบโดยตรงกับ`,
  our_game_provides: `ผู้ให้บริการเกมของเรา`,
  linked_successfully_x: `เชื่อมโยงกับ {{x}} สำเร็จแล้ว! กรุณาลงทะเบียนบัญชีของคุณเป็นครั้งแรก`,
  more_x_of_y: `เพิ่มเติม {{x}}/{{y}}`,
  agent: 'ตัวแทน',
  download: 'ดาวน์โหลด',
  popular: 'เป็นที่นิยม',
  claim_bonus: `รับโบนัส`,
  currently_displaying: 'กำลังแสดงเกม {{x}} {{y}} เกมจาก {{z}}',
  no_results: 'ไม่พบผลลัพธ์',
  please_scan_using_your: `กรุณาสแกนโดยใช้ของคุณ`,
  activity_rules: `กฎกิจกรรม:`,
  event_instructions: 'คำแนะนำกิจกรรม',
  agent_p1: 'แชร์ลิงก์เฉพาะของคุณผ่านแอพและโซเชียลมีเดีย',
  agent_p2:
    'Facebook, Instagram, Tiktok, Telegram, WhatsApp, YouTube และอื่นๆ รีบเชิญทุกคนได้เลย สมาชิกที่ลงทะเบียนผ่านลิงก์พิเศษของคุณจะกลายเป็นตัวแทนโดยตรงของคุณ แต่ควรใส่ใจเพราะสมาชิกใหม่ที่คุณเชิญจะต้องเป็นของแท้และถูกต้อง',
  agent_step1:
    'รับเพื่อนเชิญเมื่อฝากเงินครั้งแรกสำเร็จ คุณสามารถรับโบนัส R$5 ตัวอย่างเช่น หากคุณเชิญ 10 คนในการฝากเงินครั้งแรก คุณจะได้รับรายได้ R$50 ยิ่งคุณเชิญคนมากเท่าไร คุณก็จะได้รับโบนัสมากขึ้นเท่านั้น คุณสามารถดูประวัติของคุณและลงทะเบียนได้ที่หน้าโปรโมชั่นโบนัสและค่าคอมมิชชั่นสูง',
  agent_step2:
    'หากแขกของคุณเดิมพัน R$1 หรือมากกว่า คุณก็สามารถรับค่าคอมมิชชั่นที่สูงได้แล้ว! คุณสามารถรับค่าคอมมิชชั่นตลอดชีพสูงถึง 5% และคำเชิญตลอดชีวิต! ระบบจะชำระค่าคอมมิชชั่นของวันก่อนหน้าเวลา 7.00 น. ของวันถัดไป',
  agent_step2_1: 'วิธีรับ: เข้าสู่ระบบ - โปรโมชั่นคำเชิญ - สะสมคอมมิชชัน',
  agent_table1: 'อัตราค่าคอมมิชชันการเดิมพันสำหรับสล็อต ตกปลา และบล็อคเชนมีดังนี้:',
  agent_table1_1: 'ลูกค้าเติมเงิน 20 เดิมพัน 200',
  person: 'บุคคล',
  agent_table2_title1: 'การเดิมพันสมาชิก',
  agent_table2_title2: 'เกมสล็อตเท่านั้น',
  agent_table2_example:
    'ตัวอย่าง: คุณมีสมาชิก 100 คน และมูลค่าจริงของการเดิมพันทั้งหมดประมาณ 1 ล้านเรียลต่อวัน ค่าคอมมิชชันที่คุณได้รับในวันนั้นคือ: 1,000,000 x 1.20% 12,000 เรียล รายได้ต่อเดือน 100,000 นั้นง่ายมากที่จะบรรลุ',
  reminder: 'คำเตือน',
  agent_reminder:
    'บัญชีสมาชิกที่มี IP อุปกรณ์เดียวกัน ชื่อ บัตรธนาคาร และข้อมูลอื่นๆ สามารถเข้าร่วมได้เพียงครั้งเดียว และใครก็ตามที่ละเมิดกฎจะไม่ได้รับประโยชน์จากโบนัสนี้ หรือกลุ่มจะถูกยึดและบัญชีจะถูกระงับอย่างถาวร',
  agent_step3_1:
    'การมีส่วนร่วมของสมาชิกในกิจกรรมจะถูกนับโดยอัตโนมัติโดยระบบ หากมีการคัดค้านใด ๆ ผลการวิเคราะห์ที่ดำเนินการโดย Loma88 จะมีผลเหนือกว่า',
  agent_step3_2:
    'หากคุณลืมบัญชีสมาชิก/รหัสผ่าน โปรดติดต่อทีมบริการลูกค้าตลอด 24 ชั่วโมงของเราเพื่อช่วยคุณกู้คืนข้อมูลบัญชีของคุณ',
  agent_step3_3: 'Loma88 ขอสงวนสิทธิ์ในการเปลี่ยนแปลง ขัดขวาง หรือยกเลิกโปรโมชั่นได้ตลอดเวลา',
  agent_step3_4: 'ตัวแทนที่สามารถรับโบนัสคอมมิชชั่นได้คือเงื่อนไขเงินสดหรือเครดิตในกระเป๋าเงิน',
  agent_step3_5: 'อย่าบันทึกเกมฟรี ซื้อเกมฟรี',
  agent_step3_6: 'ไม่สามารถใช้ร่วมกับโบนัสและเงินฝากใดๆ ได้',
  agent_step3_7: 'ใช้ได้เฉพาะกับเกมสล็อตเท่านั้น',
  promo_concluded: 'โปรฯ สิ้นสุดแล้ว! กลับมาตรวจสอบอีกครั้งพรุ่งนี้!',
  can_only_claim: 'สามารถรับโบนัสได้ระหว่าง {{x}} ถึง {{y}} ตามเวลาประเทศไทยเท่านั้น',
  please_login: 'กรุณาเข้าสู่ระบบก่อนเพื่อรับโบนัส!',
  back_to_home: 'กลับไปที่บ้าน',
  bonus_claimed: 'ขอรับโบนัสแล้ว!',
  terms_n_conditions: `TERMS AND CONDITION`,
  the_activity_x_in: `กิจกรรม {{x}} ใน`,
  starts: `เริ่มต้น`,
  ends: `สิ้นสุด`,
  list_of_winners: `รายชื่อผู้ชนะ`,
  days: `วัน`,
  hours: `ชั่วโมง`,
  minutes: `นาที`,
  seconds: `วินาที`,
  apply_now: `สมัครตอนนี้`,
  number_exists: 'หมายเลขโทรศัพท์นี้ลงทะเบียนแล้ว!',
  number_not_exists: 'หมายเลขโทรศัพท์นี้ไม่ได้ลงทะเบียน!',
  payment_draft: 'หากคุณยืนยันว่าคุณได้ชำระเงินแล้ว โปรดรอสักครู่ เนื่องจากการชำระเงินอาจยังอยู่ระหว่างดำเนินการ',
  downline_name: 'ชื่อดาวน์ไลน์',
  date_joined: 'วันที่เข้าร่วม',
  deposit_amount_x_days: 'จำนวนเงินฝาก ({{x}} วัน)',
  dont_show_again: 'อย่าแสดงอีก',
  next: 'ต่อไป',
  close: 'ปิด',
  no_balance: 'ไม่มียอดคงเหลือ',
  menu: `เมนู`,
  vip_club: `วีไอพีคลับ`,
  home: `บ้าน`,
  lobby: `ล็อบบี้`,
  show_all: `แสดงทั้งหมด`,
  game_name: `ชื่อเกม`,
  sub_filters: `ตัวกรองย่อย`,
  contact_us_24: `ติดต่อเรา บริการตลอด 24 ชั่วโมง`,
  live_chat: `แชทสด`,
  chat_now: `แชทเลย`,
  gaming_license: `ใบอนุญาตการเล่นเกม`,
  certification: `การรับรอง`,
  security: `ความปลอดภัย`,
  threatmetrix: `ThreatMetrix`,
  gaming_license_caption: `Loma88 เป็นนิติบุคคลที่ได้รับอนุญาตและควบคุมโดยรัฐบาล Curacao และดำเนินการอย่างถูกกฎหมายภายใต้ใบอนุญาตหลักของผู้ให้บริการเกม N.V #365/JAZ`,
  no_limits: `ไม่มีขีดจำกัด`,
  vip_description_no_limits: `เรานำเสนอบริการวีไอพีส่วนตัวพร้อมเงื่อนไขการเล่นเกมที่ไม่เหมือนใครให้กับผู้เล่นทุกคนในคลับวีไอพี ตอนนี้คุณสามารถเล่นได้โดยไม่ต้องกังวล! มันง่ายมาก - เล่นเพื่อความตื่นเต้น พัฒนาทักษะการเล่นเกมของคุณ เพลิดเพลินกับเวลาของคุณและไม่จำกัดโอกาสในการชนะของคุณ!`,
  financial_privileges: `สิทธิพิเศษทางการเงิน`,
  vip_description_financial_privileges: `VIP club ยินดีต้อนรับผู้เล่นทุกคนที่ต้องการรับบริการ VIP คุณภาพสูง สิทธิพิเศษ โปรโมชั่นพิเศษ และสิทธิประโยชน์มากมายสำหรับคุณที่เว็บไซต์ของเรา! สถานะของผู้เล่น VIP จะปลดล็อกขีดจำกัดการถอนเงินที่สูงขึ้นในระยะเวลาสั้นที่ทำลายสถิติ! สนุกกับมัน!`,
  personal_manager: `ผู้จัดการส่วนตัว`,
  vip_description_personal_manager: `ข้อดีใดที่คุณต้องการได้รับเมื่อคุณเป็นผู้เล่น VIP? แบ่งปันความคิดของคุณกับผู้จัดการส่วนตัวของคุณและแจ้งให้เราทราบว่าคุณยินดีที่จะเห็นอะไรอีกที่เว็บไซต์ของเรา! เราพร้อมรับข้อเสนอแนะดีๆ อยู่เสมอ!`,
  vip_promotions: `โปรโมชั่นวีไอพี`,
  vip_description_vip_promotions: `คุณต้องการที่จะรับโปรโมชั่นดีๆ มากขึ้น ผู้เล่นวีไอพีที่แข็งแกร่งเพลิดเพลินไปกับสิ่งเหล่านี้และสิทธิพิเศษอื่น ๆ อีกมากมายเป็นรางวัลสำหรับความภักดี! อยู่ในนั้น!`,
  withdraw_limit: `ถอนวงเงิน`,
  monthly: `รายเดือน`,
  weekly: `รายสัปดาห์`,
  annual: `ประจำปี`,
  cashback: `คืนเงิน`,
  personal_account_manager: `ผู้จัดการบัญชีส่วนบุคคล`,
  yes: `ใช่`,
  join_the_vip: `เข้าร่วมโปรแกรมวีไอพีทันที!`,
  join_now: `เข้าร่วมตอนนี้`,
  play_the_way_you_want: `เล่นในแบบที่คุณต้องการ`,
  more_info: `ข้อมูลเพิ่มเติม`,
  nothing_to_show: `ไม่มีอะไรจะแสดง`,
  remarks: `หมายเหตุ`,
  password: `รหัสผ่าน`,
  back: `กลับ`,
  new_to_x: `ใหม่กับ {{x}}`,
  create_an_account: `สร้างบัญชี`,
  withdraw_amount: `ถอนจำนวนเงิน`,
  balance: `สมดุล`,
  bank_bsb: `Bank BSB`,
  enter_bank_branch: `Enter Bank Branch`,
  enter_pay_id: `Enter Pay ID`,
  level: `ระดับ`,
  claimed: `อ้างสิทธิ์`,
  not_found: `ไม่พบ`,
  become_a_vip: `มาเป็นวีไอพี`,
  otp_not_found: `OTP ไม่ได้ตั้งค่า`,
  resend_again: `ส่งอีกครั้ง`,
  how_to_get_referral_bonus: 'วิธีรับโบนัสเพื่อนผู้อ้างอิง?',
  steps_to_claim: 'ขั้นตอนการรับโบนัส',
  share_your_referral_code:
    '1. แชร์รหัสอ้างอิงของคุณให้เพื่อนของคุณเพื่อลงทะเบียนบัญชีผ่าน Facebook, Twitter หรือ WhatsApp',
  after_friend_register:
    '2. หลังจากที่เพื่อนลงทะเบียนกับ Loma88 ทั้งผู้แนะนำและสมาชิกผู้แนะนำจะต้องทำการฝากเงินเพื่อรับโบนัสนี้',
  refer_a_friend_bonus: '3. การคำนวณโบนัสแนะนำเพื่อน:',
  turnover_played: 'มูลค่าการซื้อขายที่เล่น x ค่าคอมมิชชัน % = โบนัสเพื่อนผู้อ้างอิง',
  example: 'ตัวอย่าง:',
  turnover_formula_x: 'มูลค่าการซื้อขาย (1,000,000) x 0.002% (คอมมิชชั่น %) = 2,000{{x}}',
  note_bonus_only: '*หมายเหตุ: โบนัสสามารถรับได้ในวันถัดไปหลัง 00:00 น. เท่านั้น',
  note_bonus_only_x: '*หมายเหตุ: โบนัสสามารถรับได้ในวันถัดไปหลังจาก {{x}} เท่านั้น',
  access_dashboard: '4. เข้าถึงแดชบอร์ดเพื่อตรวจสอบผลกำไรที่ได้รับ ระดับคอมมิชชั่น ประวัติการเรียกร้อง และดาวน์ไลน์',
  // TODO: TRANSLATE TO OTHER LANG
  highlights: 'ไฮไลท์',
  event: 'เหตุการณ์',
  information: 'ข้อมูล',
  casino: 'คาสิโน',
  fish: 'ปลา',
  sports: 'กีฬา',
  card: 'การ์ด',
  popular_games: 'เกมส์ยอดนิยม',
  game_providers: 'ผู้ให้บริการเกม',
  leaderboard: 'ลีดเดอร์บอร์ด',
  winnings: 'ชัยชนะ',
  vip_member_level: `ระดับสมาชิกวีไอพี`,
  change_language: `เปลี่ยนภาษา`,
  contact: 'ติดต่อ',
  choose_deposit_method: 'เลือกวิธีการฝากเงิน',
  your_bank_account: 'บัญชีธนาคารของคุณ',
  x_games: '{{x}} เกม',
  x_game: '{{x}} เกม',
  fishing: 'ตกปลา',
  please_enter_email: 'กรุณากรอกอีเมลของคุณ',
  please_enter_new_password: 'กรุณากรอกรหัสผ่านใหม่ของคุณ',
  password_not_match: 'รหัสผ่านไม่ตรงกัน',
  confirm_new_password: 'ยืนยันรหัสผ่านใหม่'
};

export default th;
