import { Box, Tab, Typography, styled } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { TabContext, TabList } from '@mui/lab';
import { useLocation, useNavigate, useParams } from 'react-router';
import Image from '../../components/Image';
import palette from '../../theme/palette';
import useLocales from '../../hooks/useLocales';
import { useSelector } from '../../redux/store';
import Iconify from '../../components/Iconify';

const getGameCateg = (slug) => {
  let nav = '';

  if (slug.indexOf('slots') !== -1) nav = { src: require('../../assets/menu/nav-slot-white.png') };

  if (slug.indexOf('live-casino') !== -1) nav = { src: require('../../assets/menu/nav-live-white.png') };

  if (slug.indexOf('fishing') !== -1) nav = { src: require('../../assets/menu/nav-fish-white.png') };

  if (slug.indexOf('card') !== -1) nav = { src: require('../../assets/menu/nav-card-white.png') };

  if (slug.indexOf('sports') !== -1) nav = { src: require('../../assets/menu/nav-sport-white.png') };

  if (slug.indexOf('e-sports') !== -1) nav = { src: require('../../assets/menu/nav-e-sports-white.png') };

  if (slug.indexOf('instant-game') !== -1) nav = { src: require('../../assets/menu/nav-ig-white.png') };

  return nav;
};

const SubHeaderContainer = styled(Box)(() => ({
  boxShadow: '0px 1px 5px 5px rgb(0, 0, 0, 0.2)',
  height: '4.2rem',
  background: palette.navBarVertical.subBg,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: '.5rem',
  paddingLeft: '.5rem',
  overflow: 'hidden',
  width: '100%',
}));

const SubHeaderNav = () => {
  const { translate } = useLocales();

  const { gameCategsAndCompanies } = useSelector((x) => ({ ...x.game, ...x.lookup }));

  const [activeSubTab, setActiveSubTab] = useState('lobby');

  const navigate = useNavigate();

  const params = useParams();

  const navButtons = useMemo(() => {
    const _navButtons = [
      {
        title: translate('lobby'),
        value: 'lobby',
        imageSrc: { src: require('../../assets/menu/nav-home-white.png') },
        action: () => {
          setActiveSubTab('lobby');
          navigate('/home');
        },
      },
      // {
      //   title: translate('VIP Club'),
      //   value: 'vip',
      //   imageSrc: { src: require('../../assets/menu/nav-vip.png') },
      //   action: () => {
      //     if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
      //     else {
      //       setActiveSubTab('vip');
      //       navigate('/vip');
      //     }
      //   },
      // },
    ];

    if (gameCategsAndCompanies?.length) {
      gameCategsAndCompanies.forEach((categ) => {
        _navButtons.push({
          title: categ?.name,
          value: categ?.slug,
          imageSrc: getGameCateg(categ?.slug),
          action: () => onCategClick(categ?.slug),
        });
      });
    }

    return _navButtons;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameCategsAndCompanies]);

  useEffect(() => {
    if (params?.categSlug) {
      setActiveSubTab(params?.categSlug);
    }
  }, [params]);

  // useEffect(() => {
  //   if (location.pathname === '/vip') {
  //     setActiveSubTab('vip');
  //   }
  // }, [location]);

  const onCategClick = (slug) => {
    setActiveSubTab(slug);
    navigate(`/games/${slug}`);
  };

  return (
    <SubHeaderContainer>
      <Box
        sx={{
          width: '100%',
          maxWidth: '1200px',
          overflow: 'hidden',
          '.MuiTabs-flexContainer': {
            marginLeft: '10px !important',
            marginRight: '10px !important',
          },
        }}
      >
        <TabContext value={activeSubTab}>
          <Box sx={{ borderBottom: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <TabList
              TabIndicatorProps={{
                style: { backgroundColor: 'transparent' },
              }}
              sx={{
                minHeight: 'unset',
                color: 'white',
                flex: { xs: 1, md: 'none' },
              }}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              TabScrollButtonProps={{
                sx: {
                  width: '0.5rem',
                  borderRadius: 0,
                },
              }}
            >
              {navButtons.map((nav, idx) => (
                <Tab
                  key={idx}
                  sx={{
                    justifyContent: 'center',
                    marginRight: idx !== navButtons.length - 1 ? '0.3rem !important' : 0,
                    background:
                      activeSubTab === nav.value ? 'linear-gradient(180deg, #FEE504 0%, #ED7E40 100%)' : 'transparent',
                    boxShadow: activeSubTab === nav.value ? '0px 0px 8px 0px #FFD633' : 'transparent',
                    border: activeSubTab === nav.value ? '1px solid #FFF1B9' : 'none',
                    cursor: 'pointer',
                    minHeight: 'unset',
                    minWidth: 'unset',
                    flex: 1,
                    borderRadius: '8px',
                    py: '5px',
                    my: '10px',
                  }}
                  onClick={nav.action}
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                        minWidth: '60px',
                        flex: 1,
                      }}
                    >
                      <Box
                        sx={{
                          flexGrow: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: '5px 0 2px 0',
                          flex: 1,
                        }}
                      >
                        {nav.imageSrc?.src ? (
                          <Box sx={{ width: 25, height: 25, display: 'flex', alignItems: 'center' }}>
                            <Image src={nav.imageSrc.src} sx={{ width: '100%', height: 'auto' }} />
                          </Box>
                        ) : (
                          <Iconify icon={nav.imageSrc?.icon} sx={{ width: 25, height: 25 }} />
                        )}
                      </Box>
                      <Typography
                        fontSize={{ xs: '0.4rem', md: '0.6rem' }}
                        fontWeight={900}
                        sx={{
                          whiteSpace: 'nowrap',
                          width: '100%',
                          marginLeft: nav.value === 'slots' ? '-3px' : 0,
                          mt: '2px',
                        }}
                      >
                        {nav.title}
                      </Typography>
                    </Box>
                  }
                  value={nav.value}
                />
              ))}
            </TabList>
          </Box>
        </TabContext>
      </Box>
    </SubHeaderContainer>
  );
};

export default SubHeaderNav;
