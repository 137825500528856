import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#00AB55',
  dark: '#007B55',
  darker: '#005249',
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_12],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: '#fff',
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  header: {
    bgColor: '#b2ff01',
    menuBg: '#01a101',
    menu: '#fff',
    deposit_btn: {
      bg: 'linear-gradient(180deg, #FEE504 0%, #ED7E40 100%)',
      color: '#fff',
    },
    login_btn: {
      border: '#f8da4a',
      bg: 'linear-gradient(0deg, rgb(170, 37, 27), rgb(235, 68, 58))',
      color: '#fff',
    },
    register_btn: {
      border: '#f8da4a',
      bg: 'linear-gradient(0deg, rgb(14, 42, 148), rgb(43, 102, 225))',
      color: '#fff',
    },
    balance_button: {
      bg: 'rgba(255,255,255,0.1)',
      color: '#fff',
    },
  },

  navBarVertical: {
    bg: '#1D0059',
    subBg: 'linear-gradient(180deg, #2D3B69 0%, #172039 100%)',
    menu: {
      bgColor: '#01a101',
      color: '#fff',
    },
  },

  bottomMenu: {
    bgColor: '#FFD633',
    color: `#fff`,
    depositBg: `linear-gradient(#1C1E22 0px, #1C1E22 100%)`,
    depositBorder: '#0d840d',
  },

  home: {
    announcement: {
      bg: 'linear-gradient(90deg, rgba(63,56,105,0.6) 0%, rgba(49,47,64,0.6) 100%)',
      color: '#fff',
    },
    tab: {
      borderBottom: '#c7fe27',
      bgActive: '#c7fe27',
      bgInActive: 'transparent',
      textActive: '#009115',
      textInactive: '#c7fe27',
    },
    sort: {
      labelColor: '#c7fe27',
    },
  },

  footer: {
    bgColor: '#1D0059',
    bgColor2: '#1D0059',
    bgColor3: '#1D0059',
  },

  button: {
    bg: 'linear-gradient(180deg, #FEFFFF 0%, #6BA4FF 150%)',
    bg2: 'linear-gradient(180deg, #5E00D5 0%, #4F0075 100%)',
    shadow: '0px 0px 13px 2px #8000FF',
    border: '1px solid #7058FF',
    border2: '1px solid #FFB9EB',
    gameShadow: '0px 0px 5px 0px #FFD633',
    color: '#fff',
  },

  visitor: {
    dialog: {
      title: '#fff',
      loginDirectly: '#000',
      footerText: '#fff',
      hr: `#fff`,
      otherText: '#fff',
    },
    icon: 'grey',
    input: {
      border: '#D994E6',
      color: '#fff',
      placeholder: '#77578F',
      bg: '#370051',
    },
    link: '#ffd205',
    errorText: '#bd0000',
    login: {
      buttonBg: 'linear-gradient(0deg, rgba(63,134,237,1) 0%, rgba(9,185,252,1) 100%)',
      color: '#000',
      border: '#41a4ff',
    },
    register: {
      buttonBg: 'linear-gradient(0deg, rgba(63,134,237,1) 0%, rgba(9,185,252,1) 100%)',
      color: '#fff',
      border: '#41a4ff',
    },
  },

  transactionDialog: {
    bg: '#0d1a8c',
    border: '#c7fe27',
    tab: {
      bg: '#000000',
      active: '#373644',
      inactive: 'transparent',
      activeTxt: '#fff',
      inactiveTxt: '#fff',
    },
    deposit: {
      copy: '#000',
      inputLabel: '#fff',
      smallText: '#fff',
      largeText: '#fff',
      suggestedTopupBg: '#320084',
      suggestedTopupTxt: '#fff',
      smallPromoBox: {
        bg: '#9B85D7',
        active: '#D9D0F1',
        color: '#000',
      },
      bonusBox: {
        bg: '#320084',
        color: '#fff',
        border: 'transparent',
      },
      select: {
        bg: '#1F0059',
        color: '#fff',
        border: '#9B85D7',
      },
      selectedBankBox: {
        bg: '#320084',
        border: '#fff4ba',
      },
      depositInputBox: {
        bg: '#120033',
        border: 'transparent',
      },
      depositInput: {
        color: '#fff',
      },
      bankRefNumber: {
        color: '#000',
        border: '#fff4ba',
        outlineBg: '#ffde4a',
        placeholder: '#000',
      },
      confirmBtn: 'linear-gradient(180deg, #FEE504 0%, #ED7E40 100%)',
      bepositBtnText: '#fff',
      depositAmt: '#fff',
    },
    withdraw: {
      text: '#fff',
      suggestedWthdrwBg: '#b19100',
      availableBalanceTxt: 'rgba(255, 255, 255, 0.85)',
      withdrawInputBox: {
        bg: '#120033',
        border: '#9B85D7',
        shadow: 'rgba(23,125,220,.2)',
      },
      noBankAccount: '#fff',
      withdrawText: '#fff',
      confirmBtnBg: 'linear-gradient(180deg, #FEE504 0%, #ED7E40 100%)',
    },
  },

  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: '#252631', default: '#252631', neutral: GREY[500_16], dark: '#1D1C21' },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
